
import { defineComponent } from "vue";

export default defineComponent({
  name: "pageNotFound",
  title() {
    return `Page not found | My Culture`;
  },
  mounted() {
    this.$store.dispatch("forceReady", null, { root: true });
  },
  methods: {
    openLink(value: any) {
      if (value === "ladingPage") {
        window.open("https://myculture.happily.ai/", "_self");
      } else {
        window.open("https://myculture.happily.ai/", "_self");
      }
    },
  },
});
